<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-card class="shadow">
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="12">

                <v-card flat>

                  <v-overlay :value="overlay"
                             absolute
                             color="white"
                             opacity="0.5">
                    <v-progress-circular
                        indeterminate
                        size="50"
                        color="primary"
                    ></v-progress-circular>
                  </v-overlay>

                  <v-card-title>
                      Gestion des boutiques
                    <v-spacer/>

                    <v-btn color="gifty"
                           dark
                           class="rounded-lg"
                           depressed
                           @click="addStore">
                      <v-icon left>mdi-plus</v-icon>
                      Ajouter
                    </v-btn>

                  </v-card-title>

                  <v-divider/>

                  <v-card-text v-if="isLoading">
                    <v-skeleton-loader type="table"/>
                  </v-card-text>

                  <div v-else>

                    <v-card-text class="pa-0">

                      <v-simple-table v-if="stores.length">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th>Active</th>
                            <th>Image</th>
                            <th>Nom complet</th>
                            <th>Nom d'utilisateur</th>
                            <th>Email</th>
                            <th>Téléphone</th>
                            <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item,i) in stores" :key="i">
                            <td>
                              <v-switch class="mt-0 pt-0"
                                        @click="changeStatus(item.id,i)"
                                        readonly
                                        v-model="item.is_active"
                                        hide-details color="gifty"/>
                            </td>
                            <td>

                              <v-avatar size="32">
                                <v-img v-if="item.account && item.account.photo" :src="fileUrl+item.account.photo"></v-img>
                                <v-img v-else :src="require('@/assets/no-image.png')"></v-img>
                              </v-avatar>

                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.username }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.phone }}</td>
                            <td>
                              <div class="d-flex">
                                <v-btn icon @click="update(item)">
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn icon @click="remove(item.id)">
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <div v-else>
                        <NoResults @reload="getStores"/>
                      </div>

                    </v-card-text>

                  </div>

                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <FormDialog ref="formDialog" @refresh="getStores"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FormDialog from "./FormDialog";

export default {
  components: {FormDialog, NoResults},
  data() {
    return {
      id: null,
      fileUrl: process.env.VUE_APP_FILE_URL,

      isLoading: false,
      overlay: false,
      stores: []
    };
  },
  methods: {
    getStores() {
      this.isLoading = true
      HTTP.get('/v1/partner-vouchers/stores').then(res => {
        this.isLoading = false
        this.stores = res.data.data
        this.$store.commit('stores', this.stores)
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    addStore() {
      this.$refs.formDialog.open()
    },
    update(item) {
      this.$refs.formDialog.open(item)
    },
    remove(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    deleteItem() {
      this.overlay = true
      let data = {
        id: this.id
      }
      HTTP.post('/v1/partner-vouchers/stores/delete', data).then(() => {
        this.overlay = false
        let i = this.stores.findIndex(el => el.id === this.id)
        this.$delete(this.stores, i)
        this.id = null
        this.$store.commit('stores', this.stores)
      }).catch(err => {
        this.overlay = false
        console.log(err)
      })
    },
    changeStatus(id, i) {
      this.overlay = true
      HTTP.post('/v1/partner-vouchers/stores/change-status', {
        store_id: id
      }).then(() => {
        this.overlay = false
        this.stores[i].is_active = !this.stores[i].is_active
      }).catch(err => {
        this.overlay = false
        console.log(err)
      })
    }
  },
  created() {
    this.getStores()
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem()
        this.$is_confirm = false
      }
    },
  },
};
</script>

<style scoped></style>
